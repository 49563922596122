@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Anek+Devanagari:wght@100..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400..800;1,400..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400..800;1,400..800&family=Source+Serif+4:ital,opsz,wght@0,8..60,200..900;1,8..60,200..900&display=swap');


.font-anek-devanagari {
  font-family: "Anek Devanagari", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
}

.font-eb-garamond {
  font-family: "EB Garamond", serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: italic;
}

.font-source-serif-4 {
  font-family: "Source Serif 4", serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: italic;
}

.font-source-serif-description {
  font-family: "Source Serif 4", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.font-source-serif-heading {
  font-family: "Source Serif 4", serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}

.font-gloock-regular {
  font-family: "Gloock", serif;
  font-weight: 400;
  font-style: normal;
}



.about-us-container {
    padding: 40px;
    max-width: 800px;
    margin: auto;
    margin-top: 5rem;
    text-align: left;
  }
  
  h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  p {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
  
  .contact-us-container {
    padding: 40px;
    max-width: 800px;
    margin: auto;
    margin-top: 5rem;
    text-align: left;
  }
  
  h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .contact-info {
    margin-top: 20px;
  }
  
  a {
    color: rgb(0, 0, 0);
    /* text-decoration: underline; */
  }
  
  a:hover {
    text-decoration: none;
  }
  
  /* src/components/Navbar.css */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* background-color: #d40000; */
    padding: 20px 50px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
  }
  
  .navbar-logo .logo {
    
    cursor: pointer;
  }
  
  .navbar-links {
    display: flex;
    gap: 20px;
  }
  
  .nav-link {
    color: #333;
    text-decoration: none;
    font-weight: 500;
    padding: 10px 15px;
    border-radius: 5px;
    transition: background-color 0.2s ease-in-out;
  }
  
  .nav-link:hover {
    background-color: #e0e0e0;
  }
  
  .navbar-register .register-button {
    padding: 10px 20px;
    background-color: #3378c2;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
    transition: background-color 0.3s ease-in-out;
  }
  
  .register-button:hover {
    background-color: #0056b3;
  }

  .brochure_btn{
    transition:0.2s ease-in-out
  }

  html {
    scroll-behavior: smooth;
  }
  main {
    padding-top: 70px; /* Adjust based on the navbar height */
  }

  /* App.css */
body {
  margin: 0;
  padding: 0;
  /* overflow-x: hidden; */
  font-family: Arial, sans-serif;
  background-image: url('../public/images/landing_pg_main_bg.jpg');
  background-size: cover;
  background-position: center; /* Centers the background image */
  background-repeat: no-repeat; /* Prevents tiling */
  height: 100vh; /* Ensures full height coverage */
  width: 100vw;
}

.section2{
  background-image: url('../public/images/section2_checked_bg.png');
  background-size: cover;
}

.day1-card{
  background-image: url('../public/images/section2_day1_card_bg.png');
  background-size: cover;
  box-shadow: 0 8px 16px 2px rgba(0, 0, 0, 0.20);
}

.day2-card{
  background-image: url('../public/images/section2_day2_card_bg.png');
  background-size: cover;
  box-shadow: 0 8px 16px 2px rgba(0, 0, 0, 0.20);
}

.arrow-pointer{
  background-image: url('../public/images/triangle_pointer_icon.png');
  background-size: cover;
}

.section-divider{
  background: #C04367;
}

.magazine-mockup{
  background-image: url('../public/images/magazine_mockup.png');
  background-size: cover;
  
  background-repeat: no-repeat;
}

.section3-magazine-faded-divider{
  background-image: url('../public/images/faded_divider.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.section3{
  background-image: url('../public/images/section3_bg.png');
  background-size: cover;
  
}

.section3_vector{
  background-image: url('../public/images/section_vector.png');
  background-size: cover;
  background-repeat: no-repeat;
  
  background-position-x: 50%;
  
}


.launching_textbox{
  border-radius: 10px 10px 10px 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.launchingdate_textbox{
  border-radius: 20px 20px 20px 20px;

  transition:  0.3s ease-in-out;
  background-color: #BF4D6D;
  box-shadow: 
    2px 2px 5px rgba(0, 0, 0, 0.1), 
    4px 4px 15px rgba(0, 0, 0, 0.2);
}

.launchingdate_textbox:hover{
  background-color: #a83354;
}

.videocard{
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);

}



.star_card{
  background-image: url('../public/images/card_star.png') ;
  background-size: cover;
  background-repeat: no-repeat;
}



@media (max-width: 768px) {
  .section2 {
    background-image: url('../public/images/checked_pattern_mobile.png');
  }
}

@media (min-width: 768px) {
  .section2 {
    background-image: url('../public/images/checked_pattern_desktop.png');
  }
}

@media (max-width: 768px) {
  .section3 {
    background-position: left;
    
  }
}



html, body {
  overflow: auto;
}

::-webkit-scrollbar {
  width: 0; /* Hides the vertical scrollbar */
  height: 0; /* Hides the horizontal scrollbar */
}

/* Custom Scrollbar Styles */
::-webkit-scrollbar {
  width: 3px; /* Width of the vertical scrollbar */
  height: 6px; /* Height of the horizontal scrollbar */
}

::-webkit-scrollbar-thumb {
  background-color: #3b3b3b; /* Color of the scrollbar thumb */
  border-radius: 8px; /* Rounded edges for the thumb */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #1d2047; /* Thumb color on hover */
}

::-webkit-scrollbar-track {
  background: transparent; /* Color of the scrollbar track */
  border-radius: 8px; /* Rounded edges for the track */
}
  
  /* App.css */

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Inter','Roboto','Arial', sans-serif;
}



.instaLogo{
  background-image: url('../public/images/Instagram_Circle.png');
  background-size: cover;
}

.previous_btn{
  background-color: #d8d8d8;
  color: #333;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s ease-in-out;
}

.next_btn{
  background-color:#3e79b4;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s ease-in-out;
}

.submit_btn{
  background-color: #3e79b4;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s ease-in-out;
}

.addTeamMember_btn{
  background-color: #ffffff;
  color: #3e79b4;
  padding: 10px 20px;
  border-radius: 5px;
  border: 2px solid #3e79b4;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s ease-in-out;
}
.plus_symbol{
  font-size: 1.5rem;
  font-weight: bold;
  margin-right: 5px;
}

.removeTeamMember_btn{
  background-color: #ffffff;
  color: #b43e3e;
  padding: 10px 20px;
  border-radius: 5px;
  border: 2px solid #b43e3e;
  
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s ease-in-out;
}

.home_register_btn{
  background-color: #3e79b4;
  color: white;
  padding: 8px 25px;
  border-radius: 30px;
  border: none;
  cursor: pointer;
  font-weight: 600;
  transition: background-color 0.2s ease-in-out;
} 
.navbar_register_btn{
  background-color: #3e79b4;
  color: white;
  padding: 8px 30px;
  border-radius: 30px;
  border: 1px solid #909090;
  cursor: pointer;
  font-weight: 600;
  transition: background-color 0.2s ease-in-out;
}

.viewdetails_btn{
  transition: ease-in-out 0.2s;
}

/* Center the loading screen */
.loading-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7); /* Transparent dark background */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
}

/* Spinner animation */
.spinner {
  border: 8px solid #f3f3f3; /* Light gray */
  border-top: 8px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1.5s linear infinite;
}

/* Text styling */
.loading-screen p {
  margin-top: 20px;
  color: white;
  font-size: 1.5rem;
  font-family: Arial, sans-serif;
}

/* Animation keyframes */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.marquee-container {
  width: 100%;
  padding: 1rem;
  overflow: hidden;
  white-space: nowrap;
  background-color: #243447;
}



.marquee {
  display: inline-block;
  padding-left: 100%;
  animation: scroll 25s linear infinite;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
